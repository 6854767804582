export default {
  route: {
    dashboard: 'Dashboard',
    documentation: 'Documentation',
    guide: 'Guide',
    permission: 'Permission',
    customerManage: 'Customer Management',
    customerSysParaManage: 'System parameter',
    documentDefinition: 'Document Definition',
    orderDefinition: 'Order definition',
    platformCustomerManage: 'customer management',
    cusTagSeqRangeManage: 'Tag serial number',
    cusOptProtocol: 'Protocol&Driver',
    cusCodec: 'Codec settings',
    productDefinition: 'Product info definition',
    orderItemDefinition: 'Order item definition',
    orderManagement: 'Document management',
    platformSysParaSet: 'Platform parameter setting',
    cusOrgNode: 'Organization manage',
    express: 'Express config',
    expressSF: 'Shunfeng',
    labelManage: 'Rfid tag manage',
    labelPurchase: 'Purchase tags',
    labelProduction: 'Production tags',
    basedefi: 'Basic definition',
    system: 'System manage',
    baseinfodefi: 'Basic information definition',
    cusScene: 'Scenario configuration',
    cusSceneManage: 'Scene definition',
    customerBizTypeManage: 'Operation definition',
    customerUser: 'User manage',
    customerRole: 'Role manage',
    baseInfoManagement: 'Basic info',
    productManagement: 'Product info',
    terminalunit: 'Device manage',
    cusLicense: 'License settings',
    backupDir:'Backup dir config',
    downloadBackupFile:'Backup datas',
    softwareVersion:'Manage version'
  },
  navbar: {
    dashboard: 'Dashboard',
    github: 'Github',
    logOut: 'Log Out',
    profile: 'Profile',
    theme: 'Theme',
    size: 'Global Size'
  },
  login: {
    title: 'Login Form',
    logIn: 'Login',
    username: 'Username',
    password: 'Password',
    any: 'any',
    thirdparty: 'Or connect with',
    thirdpartyTips: 'Can not be simulated on local, so please combine you own business simulation! ! !'
  },
  common: {
    title: 'Title',
    type: 'Type',
    search: 'Search',
    clear: 'Clear',
    add: 'Add',
    export: 'Export',
    import: 'Import',
    index: 'Seq',
    date: 'Date',
    status: 'State',
    actions: 'Operation',
    edit: 'Edit',
    delete: 'Delete',
    view: 'View',
    cancel: 'Cancel',
    confirm: 'Confirm',
    createTime: 'Creation time',
    createrName: 'Creator',
    dataLoading: 'Loading',
    operation: 'Operation',
    tips: 'Tips',
    operationSuccess: 'Operation succeeded！',
    deleteSuccess: 'Operation succeeded！',
    insertSuccess: 'Operation succeeded！',
    updateSuccess: 'Operation succeeded！',
    importSuccess: 'Operation succeeded！',
    yes: 'Yes',
    no: 'No',
    pleaseInput: 'Please enter content',
    pleaseSelect: 'Please select',
    selectFile: 'Please select the file',
    contentTooLarge: 'The content is too long',
    contentRequired: 'The content is not allowed to be empty',
    contentReqPosInt: 'Only positive integer can be entered',
    defaultSelect: '--Default--',
    orderItems: 'Order details',
    excelFilterMsg:'Only xls or xlsx files can be uploaded',
    jarFilterMsg:'Only jar files can be uploaded',
    zipapkFilterMsg:'Only zip or apk files can be uploaded',
    needUploadFile:'Please select a file to upload',
    updatePassword:'Update password',
    logout:'Log out'
  },
  documentation: {
    documentation: 'Documentation',
    github: 'Github Repository'
  },
  permission: {
    addRole: 'New Role',
    editPermission: 'Edit',
    roles: 'Your roles',
    switchRoles: 'Switch roles',
    tips: 'In some cases, using v-permission will have no effect. For example: Element-UI  el-tab or el-table-column and other scenes that dynamically render dom. You can only do this with v-if.',
    delete: 'Delete',
    confirm: 'Confirm',
    cancel: 'Cancel'
  },
  guide: {
    description: 'The guide page is useful for some people who entered the project for the first time. You can briefly introduce the features of the project. Demo is based on ',
    button: 'Show Guide'
  },
  components: {
    documentation: 'Documentation',
    tinymceTips: 'Rich text is a core feature of the management backend, but at the same time it is a place with lots of pits. In the process of selecting rich texts, I also took a lot of detours. The common rich texts on the market have been basically used, and I finally chose Tinymce. See the more detailed rich text comparison and introduction.',
    dropzoneTips: 'Because my business has special needs, and has to upload images to qiniu, so instead of a third party, I chose encapsulate it by myself. It is very simple, you can see the detail code in @/components/Dropzone.',
    stickyTips: 'when the page is scrolled to the preset position will be sticky on the top.',
    backToTopTips1: 'When the page is scrolled to the specified position, the Back to Top button appears in the lower right corner',
    backToTopTips2: 'You can customize the style of the button, show / hide, height of appearance, height of the return. If you need a text prompt, you can use element-ui el-tooltip elements externally',
    imageUploadTips: 'Since I was using only the vue@1 version, and it is not compatible with mockjs at the moment, I modified it myself, and if you are going to use it, it is better to use official version.'
  },
  table: {
    dynamicTips1: 'Fixed header, sorted by header order',
    dynamicTips2: 'Not fixed header, sorted by click order',
    dragTips1: 'The default order',
    dragTips2: 'The after dragging order',
    title: 'Title',
    importance: 'Imp',
    type: 'Type',
    remark: 'Remark',
    search: 'Search',
    add: 'Add',
    export: 'Export',
    reviewer: 'reviewer',
    id: 'ID',
    date: 'Date',
    author: 'Author',
    readings: 'Readings',
    status: 'Status',
    actions: 'Actions',
    edit: 'Edit',
    publish: 'Publish',
    draft: 'Draft',
    delete: 'Delete',
    cancel: 'Cancel',
    confirm: 'Confirm'
  },
  example: {
    warning: 'Creating and editing pages cannot be cached by keep-alive because keep-alive include does not currently support caching based on routes, so it is currently cached based on component name. If you want to achieve a similar caching effect, you can use a browser caching scheme such as localStorage. Or do not use keep-alive include to cache all pages directly. See details'
  },
  errorLog: {
    tips: 'Please click the bug icon in the upper right corner',
    description: 'Now the management system are basically the form of the spa, it enhances the user experience, but it also increases the possibility of page problems, a small negligence may lead to the entire page deadlock. Fortunately Vue provides a way to catch handling exceptions, where you can handle errors or report exceptions.',
    documentation: 'Document introduction'
  },
  excel: {
    export: 'Export',
    selectedExport: 'Export Selected Items',
    placeholder: 'Please enter the file name (default excel-list)'
  },
  zip: {
    export: 'Export',
    placeholder: 'Please enter the file name (default file)'
  },
  pdf: {
    tips: 'Here we use window.print() to implement the feature of downloading PDF.'
  },
  theme: {
    change: 'Change Theme',
    documentation: 'Theme documentation',
    tips: 'Tips: It is different from the theme-pick on the navbar is two different skinning methods, each with different application scenarios. Refer to the documentation for details.'
  },
  tagsView: {
    refresh: 'Refresh',
    close: 'Close',
    closeOthers: 'Close Others',
    closeAll: 'Close All'
  },
  settings: {
    title: 'Page style setting',
    theme: 'Theme Color',
    tagsView: 'Open Tags-View',
    fixedHeader: 'Fixed Header',
    sidebarLogo: 'Sidebar Logo'
  },
  lanCusSysPara: {
    addConfig: 'Add config parameter',
    updateConfig: 'Update config parameter',
    paraName: 'Parameter name',
    paraValue: 'Parameter value',
    tipParaNameRequired: 'Parameter name cannot be empty',
    tipParaValRequired: 'Parameter value cannot be empty',
    addSuccessTip: 'Successfully added configuration parameters',
    updateSuccessTip: 'Successfully modified configuration parameters',
    deleteConfirmTip: 'Are you sure you want to delete this configuration parameter?',
    deleteSuccessTip: 'Successfully deleted configuration parameters',
    paraDesc: 'Parameter description'
  },
  tableDefinition: {
    editTab: 'Attribute definition tab',
    showTab: 'Background display item tab',
    consoleTab: 'Consoles display config tab',
    pdaTab: 'PDA display config tab',
    initializing: 'initialization...',
    fieldTitle: 'Label name',
    fieldName: 'Field name',
    fieldType: 'Field type',
    fieldLength: 'Length',
    fieldDecimalLength: 'Decimals',
    isRequired: 'Is required',
    isQueryItem: 'Filter item',
    queryType: 'Query type',
    isDisplayed: 'Edit item',
    displaySet:'Display settings',
    isConsoleDisplayed: 'Displayed in consoles',
    isPdaDisplayed: 'PDA filter item',
    isRelationPicture: 'Relate with product picture',
    moveUp: 'up',
    moveDown: 'down',
    customAttributes: 'Custom Attributes',
    syncField: 'Sync Fields',
    syncSuccess: 'Operation success!',
    syncFieldTips: 'Are you sure you want to sync fields from product definition?',
    deleteTips: 'Are you sure you want to delete the field?',
    fieldNameValidateTips: 'It should starts with an English letter and can only contain letters, numbers and underscores!'
  },
  dataType: {
    string: 'String',
    integer: 'Integer',
    double: 'Double',
    datetime: 'Date time',
    date: 'Date',
    text: 'Text'
  },
  queryType: {
    range: 'Range',
    matching: 'Matching',
    equalse: 'Equalse'
  },
  cusScene: {
    menuName: 'Scene name',
    menuCode: 'Scene code',
    menuIcon: 'Scene icon',
    menuIndex: 'Scene serial number',
    addSceneTitle: 'Create Scene',
    updateSceneTitle: 'Update Scene',
    addSuccessTip: 'The scene is created',
    updateSuccessTip: 'The scene is updated',
    deleteConfirmTip: 'Are you sure you want to delete the scene?'
  },
  lanOrderBizType: {
    addBizType: 'Add job type',
    updateBizType: 'Update job type',
    bizTypeCode: 'Job type code',
    bizTypeName: 'Job type name',
    bizCodePrefix: 'Prefix of order',
    operationType: 'Job type',
    relateOptType: 'Next job type',
    iconForShow: 'Menu icon',
    gePermit: 'Allow excess',
    readTid: 'Gather TID',
    noAdapter: 'No',
    defAdapter: 'Default adapter',
    addSuccessTip: 'Add job type success',
    updateSuccessTip: 'Update job type success',
    deleteConfirmTip: 'Are you sure you want to delete the job type?',
    deleteSuccessTip: 'Operation success!',
    receive: 'Put in warehouse',
    send: 'Out from warehouse',
    transferIn: 'Internal channel allocation(In)',
    transferOut: 'Internal channel allocation(Out)',
    receiveReturn: 'Receive returned productions',
    sendReturn: 'Send returned productions',
    inventory:'Inventory',
    relocation:'Relocation',
    //insideOpt: '库内操作',
    adapterProtocol: 'Interface adaptation',
    adapterClass: 'Adaptive class',
    setTmpPackSticker: 'Sticker template',
    setTmpPackList: 'Order details template',
    setTmpOitemList: 'Order template',
    templateFileName: 'Template file',
    appScene: 'Scene'
  },
  cusOrgNode: {
    nodeName: 'Organization name',
    nodeCode: 'Organization code',
    nodeType: 'Organization type',
    contactsName: 'Contacts',
    contactsNumber: 'Contact number',
    channelCode: 'Channel code',
    pchannelCode: 'Superior channel code',
    addOrgNodeTitle: 'Add organization',
    updateOrgNodeTitle: 'Update organization',
    viewOrgNodeTitle: 'View organization',
    selectedSelfErrMsg: 'You cannot select yourself as the superior organization',
    depSelectDlg: 'Please select the organization',
    factory: 'Production factory',
    warehouse: 'Warehouse',
    shop: 'Shop',
    reservoirArea: 'Internal storage area',
    shopDisSite: 'Exhibition area',
    shopFittingroom: 'Fitting Room',
    customer: 'Agent or customer',
    tagVendor: 'Tag vendor',
    headOffice: 'Headquarters',
    parentNode: 'Superior organization',
    validNodeTypeMsg: 'Please select organization type',
    deleteCurOrgNodeConfirmTip: 'Are you sure you want to delete the organization?'
  },
  lanTagSeqRange: {
    tagSeqRangeTitle: 'Tag SN range',
    startSeq: 'Minimum',
    endSeq: 'Maximum',
    validMsg: 'Please enter a positive integer',
    validCompareMsg: 'The maximum mast greater than minimum'
  },
  customer: {
    userName: 'User name',
    userAccount: 'User account',
    userNodeName: 'Organization',
    userChannelCode: 'User channel code',
    roleName: 'User role',
    frozen: 'Frozen',
    thaw: 'Unfreeze',
    Valid: 'Active',
    frozenTips: 'Are you sure you want to frozen the user?',
    thawTips: 'Are you sure you want to unfreeze the user?',
    deleteTips: 'Are you sure you want to delete the user?',
    adminName: 'Administrator name',
    adminAccount: 'Administrator account',
    updatePassword:'Update password',
    oldPassword:'Old password',
    newPassword:'New password',
    confirmPassword:'Confirm password',
    notEqualsErrTip:'The new password is inconsistent with the confirmed password. Please retry',
    samePasswordErrTip:'The new and old passwords are consistent, please change the new password'
  },
  customerRole: {
    roleName: 'Role name',
    roleType: 'Role type',
    menus: 'Permissions',
    roleType_10: 'Administrator',
    roleType_20: 'Normal role',
    deleteTips: 'Are you sure you want to delete the role?',
    trackRange:'Traceability scope'
  },
  customerCodec: {
    codecTitle: 'Codec settings',
    codecType: 'Codec type',
    codecProtocol: 'Server codec protocol',
    codecDprotocol: 'Consoles codec protocol',
    codecPprotocol: 'PDA codec protocol',
    jarFile: 'jar file',
    jsScript: 'javascript',
    dynamicBinding: 'Dynamic binding',
    encodeScript: 'Encode script',
    decodeScript: 'Decode script',
    validJarFileMsg: 'Please select protocol',
    validScriptMsg: 'Please enter script content',
  },
  cusLicense: {
    cardTitle: 'Regist license'
  },
  optProtocol: {
    addProtocol: 'Add Adapter&Driver',
    updateProtocol: 'Update Adapter&Driver',
    protocolType: 'Adapter(Driver) type',
    protocolName: 'Adapter(Driver) name',
    protocolDispfileName: 'Adapter(Driver) file',
    deskReader: 'Consoles Reader driver',
    PDAReader: 'PDA driver',
    autoEquipment: 'Auto device driver',
    orderProcess: 'Interface docking adapter',
    remoteCodec: 'Server codec adapter',
    desktopCodec: 'Consoles codec adapter',
    pdaCodec: 'PDA codec adapter',
    protocolNameRequire: 'Please enter the adapter or driver name',
    deleteProtocolConfirmTip: 'Are you sure you want to delete the adapter or driver?'
  },
  labelPurchase: {
    insertDialogTitle: 'Add',
    deleteTips: 'Are you sure you want to delete the order?',
    handleSuccessTips: 'The EPC datas have generated',
    senderName:'Manufacturer',
    receiverName:'Purchaser',
    tagItems:'Tags list',
    inputSkuTips:'Please enter SKU',
    estAmount:'Quantity',
    selectSkuTips:'Please select SKU',
    enterAmountTips:'Please enter the quantity',
    pleaseInput:'Please enter ',
    generateEPC:'Generate EPC',
    dataFileColumn:'Column names in the file',
    deleteItemTips: 'Are you sure you want to delete the item?'
  },
  baseInfo: {
    deleteTips: 'Are you sure you want to delete the basic information?',
    importText: 'Drag the file here, or click here to upload',
    importLimitText: 'Only xls or xlsx files can be uploaded!',
    currentFile: 'Current file',
    notUpload: 'No file',
    firstColumnHeader: 'First row is header',
    skuRepetitiveCoverage: 'Overwrite duplicate sku',
    startColumnIndex: 'First data line number',
    endColumnIndex: 'Last data line number',
    attribute: 'Product property name',
    columnName: 'File column',
    columnNameIndex: 'column {}',
    pictureSettings: 'Set image',
    deletePictureTips: 'Are you sure you want to delete the image?'
  },
  terminalUnit: {
    unitType: 'Terminal type',
    unitCode: 'Terminal code',
    desktop: 'Consoles',
    pda: 'PDA',
    autoChannel: 'Auto device',
    mirror: 'Magic mirror',
    deleteUnitConfirmTip: 'Are you sure you want to delete the terminal?'
  },
  expressConfig: {
    expressTitle: 'Express configuration',
    dockingParaTitle: 'Connection configuration',
    envSwitch: 'Production or test switch',
    receiveMapTitle: 'Set receiver field',
    senderMapTitle: 'Set sender field',
    country: 'Country field',
    province: 'Province field',
    city: 'City field',
    county: 'County field',
    address: 'Address field',
    contact: 'Contact field',
    phone: 'Contact number field',
    comanyName: 'Company name field',
    productDesc: 'Item name field',
    expressRemark: 'Remark field',
    curEnv: 'Current environment',
    curEnvValProd: 'Production',
    curEnvValDev: 'Testing',
    needInput: 'is required',
    billTemplateTitle: 'Express sticker template'
  },
  order: {
    deleteTips: 'Are you sure you want to delete the order?',
    orderStatePending:'Pending',
    orderStateExecuting:'Executing',
    orderStateCompleted:'Completed'
  },
  orderItems: {
    actNum: 'Actual quantity',
    difNum: 'Different quantity',
    number: 'Number',
    estAmount:'Estimated number'
  },
  plateformCustomer: {
    customerName: 'Customer name',
    contactsName: 'contacts',
    contactsNumber: 'Contact number',
    customerState: 'State',
    frozen: 'Frozen',
    unfreeze: 'Unfreeze',
    active: 'Active',
    frozenTips: 'Are you sure you want to frozen the customer?',
    unfreezeTips: 'Are you sure you want to unfreeze the customer?',
    addCustomer: 'Add customer',
    updateCustomer: 'Update customer',
    addSuccessTip: 'Successfully added new customer',
    updateSuccessTip: 'Successfully modified customer information',
    setAdminTip: 'Set admin',
    resetPassword: 'Reset password',
    resetPasswordTips: 'Are you sure you want to restore to default password?',
    customerCode:'Customer code'
  },
  versionInfo:{
    softwareName:'Software Name',
    softwareVersion:'Version',
    updateTime:'Update time',
    softwareFileName:'Upgrade file name',
    addVersionInfo:'Add version info',
    updateVersionInfo:'Update version info',
    softwareSize:'File size',
    deleteVersionInfoConfirmTip:'Are you sure you want to delete version info?'
  }
}
