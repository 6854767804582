const getters = {
    permission_routers: state => state.permission.routers,
    addRouters: state => state.permission.addRouters,
    userName: state => state.user.name,
    systemTitle: state => state.system.title,
    token: state => state.user.token,
    language: state => state.app.language,
    roles: state => state.user.roles,
    menus: state => state.user.menus,
    userNodeId: state => state.user.userNodeId,
    userType: state => state.user.userType
}
export default getters
