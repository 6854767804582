export default {

    route: {
        home: '首页',
        documentation: '文档',
        guide: '引导页',
        permission: '权限测试页',
        customerManage: '客户管理',
        customerSysParaManage: '自定义参数',
        customerBizTypeManage: '作业类型定义',
        documentDefinition: '单据定义',
        orderDefinition: '主单定义',
        platformCustomerManage: '客户管理',
        cusTagSeqRangeManage: '号段设置',
        cusOptProtocol: '协议驱动管理',
        cusCodec: '编解码设置',
        productDefinition: '产品信息定义',
        orderItemDefinition: '详单定义',
        orderManagement: '单据管理',
        platformSysParaSet: '平台参数设置',
        cusOrgNode: '组织机构管理',
        express: '快递配置',
        expressSF: '顺丰',
        labelManage: '标签管理',
        labelPurchase: '标签采购',
        labelProduction: '标签生产',
        basedefi: '基础定义',
        system: '系统管理',
        baseinfodefi: '基础信息定义',
        cusScene: '场景配置',
        cusSceneManage: '场景定义',
        customerUser: '用户管理',
        customerRole: '角色管理',
        baseInfoManagement: '基础信息管理',
        productManagement: '产品信息管理',
        terminalunit: '设备管理',
        cusLicense: '注册码',
        downloadBackupFile: '下载备份文件',
        backupDir: '备份路径设置',
        sysLog: '系统日志',
        softwareVersion: '版本管理',
        reportManagement: '报表管理',
        report: '报表',
        dataset: '数据集',
        board: '看板定义',
        echart: '图标',
        uploadProductPicture: '上传商品图片',
        reportDefinition: '报表定义'
    },
    navbar: {
        dashboard: '首页',
        github: '项目地址',
        logOut: '退出登录',
        profile: '个人中心',
        theme: '换肤',
        size: '布局大小'
    },
    login: {
        title: '系统登录',
        logIn: '登录',
        username: '账号',
        password: '密码',
        any: '随便填',
        thirdparty: '第三方登录',
        thirdpartyTips: '本地不能模拟，请结合自己业务进行模拟！！！'
    },
    common: {
        dynamicTips1: '固定表头, 按照表头顺序排序',
        dynamicTips2: '不固定表头, 按照点击顺序排序',
        dragTips1: '默认顺序',
        dragTips2: '拖拽后顺序',
        title: '标题',
        importance: '重要性',
        type: '类型',
        remark: '点评',
        search: '查询',
        clear: '清空',
        add: '添加',
        export: '导出',
        import: '导入',
        reviewer: '审核人',
        index: '序号',
        date: '时间',
        author: '作者',
        readings: '阅读数',
        status: '状态',
        actions: '操作',
        edit: '编辑',
        handle: '处理',
        generate: '生成',
        publish: '发布',
        draft: '草稿',
        delete: '删除',
        view: '查看',
        cancel: '取 消',
        confirm: '确 定',
        createTime: '创建时间',
        createrName: '创建人',
        dataLoading: '数据加载中',
        operation: '操作',
        tips: '提示',
        operationSuccess: '操作成功！',
        deleteSuccess: '删除成功！',
        insertSuccess: '添加成功！',
        updateSuccess: '修改成功！',
        importSuccess: '导入成功！',
        yes: '是',
        no: '否',
        pleaseInput: '请输入',
        pleaseSelect: '请选择',
        selectFile: '选择文件',
        contentTooLarge: '输入内容过长',
        contentRequired: '该域不允许为空',
        contentReqPosInt: '该域只能输入正整数',
        defaultSelect: '--默认--',
        orderItems: '单据明细',
        excelFilterMsg: '只能上传xls以及xlsx文件',
        jarFilterMsg: '只能上传jar文件',
        zipapkFilterMsg: '只能上传zip文件或apk文件',
        needUploadFile: '请选择文件上传',
        updatePassword: '修改密码',
        logout: '退出登录',
        test: '测试',
        return: '返回',
        save: '保存'
    },
    theme: {
        change: '换肤',
        documentation: '换肤文档',
        tips: 'Tips: 它区别于 navbar 上的 theme-pick, 是两种不同的换肤方法，各自有不同的应用场景，具体请参考文档。'
    },
    tagsView: {
        refresh: '刷新',
        close: '关闭',
        closeOthers: '关闭其它',
        closeAll: '关闭所有'
    },
    settings: {
        title: '系统布局配置',
        theme: '主题色',
        tagsView: '开启 Tags-View',
        fixedHeader: '固定 Header',
        sidebarLogo: '侧边栏 Logo'
    },
    lanCusSysPara: {
        addConfig: '添加配置参数',
        updateConfig: '修改配置参数',
        paraDesc: '参数描述',
        paraName: '参数名称',
        paraValue: '参数值',
        tipParaNameRequired: '参数名称不能为空',
        tipParaValRequired: '参数值不能为空',
        addSuccessTip: '添加配置参数成功',
        updateSuccessTip: '修改配置参数成功',
        deleteConfirmTip: '确认要删除该配置参数吗？',
        deleteSuccessTip: '删除配置参数成功'
    },
    customer: {
        userName: '用户名称',
        userAccount: '用户账号',
        userNodeName: '所属部门',
        userChannelCode: '所属渠道',
        roleName: '角色',
        frozen: '冻结',
        thaw: '解冻',
        Valid: '有效',
        frozenTips: '确认冻结该账号吗？',
        thawTips: '确认解冻该账号吗？',
        deleteTips: '确认删除该账号吗？',
        adminName: '管理员名称',
        adminAccount: '管理员账号',
        updatePassword: '修改密码',
        oldPassword: '旧密码',
        newPassword: '新密码',
        confirmPassword: '确认密码',
        notEqualsErrTip: '新密码与确认密码不一致，请重新输入',
        samePasswordErrTip: '新旧密码一致，请修改新密码',
        resetPassword: '重置密码',
        resetPasswordTips: '确定要重置该用户的密码吗？'
    },
    lanOrderBizType: {
        addBizType: '添加单据类型',
        updateBizType: '修改单据类型',
        bizTypeCode: '类型编号',
        bizTypeName: '类型名称',
        bizCodePrefix: '单号前缀',
        operationType: '作业类别',
        relateOptType: '关联类别',
        iconForShow: '菜单图标',
        gePermit: '允许超量',
        readTid: '采集防伪码',
        noAdapter: '无',
        defAdapter: '默认适配协议',
        addSuccessTip: '添加单据类型成功',
        updateSuccessTip: '修改单据类型成功',
        deleteConfirmTip: '确认要删除该单据类型吗？',
        deleteSuccessTip: '删除单据类型成功',
        receive: '收货入库',
        send: '发货出库',
        transferIn: '渠道内调拨入库',
        transferOut: '渠道内调拨出库',
        receiveReturn: '收退货',
        sendReturn: '发退货',
        inventory: '盘点',
        relocation: '移库',
        //insideOpt: '库内操作',
        bizTypeCodeChkMsg: '类型编号不能为空',
        bizTypeNameChkMsg: '类型名称不能为空',
        operationTypeChkMsg: '必须选择作业类别',
        gePermitChkMsg: '请选择是否允许超量扫描',
        adapterProtocol: '接口适配协议',
        adapterClass: '适配类',
        setTmpPackSticker: '设置箱贴模板',
        setTmpPackList: '设置箱单模板',
        setTmpOitemList: '设置总单模板',
        templateFileName: '模板文件',
        setTempFIleSuccessTip: '设置模板文件成功',
        appScene: '隶属场景'
    },
    plateformCustomer: {
        customerName: '客户名称',
        contactsName: '联系人',
        contactsNumber: '联系电话',
        customerState: '客户状态',
        frozen: '冻结',
        unfreeze: '解冻',
        active: '激活',
        frozenTips: '确认冻结该客户吗？',
        unfreezeTips: '确认解冻该客户吗？',
        addCustomer: '添加客户',
        updateCustomer: '修改客户信息',
        addSuccessTip: '添加新客户成功',
        updateSuccessTip: '修改客户信息成功',
        setAdminTip: '设置管理员',
        resetPassword: '重置密码',
        resetPasswordTips: '确认要将管理员密码恢复为默认密码吗？',
        customerCode: '总公司编号'
    },
    lanTagSeqRange: {
        tagSeqRangeTitle: '标签流水号段设置',
        startSeq: '开始值',
        endSeq: '结束值',
        validMsg: '请输入正整数',
        setSeqRangeSuccessTip: '设置标签流水号段成功',
        validCompareMsg: '结束值必须大于开始值'
    },
    tableDefinition: {
        editTab: '属性定义',
        showTab: '管理端列表排序',
        consoleTab: '作业台列表排序',
        pdaTab: '手持机过滤项排序',
        initializing: '正在初始化...',
        fieldTitle: '中文名',
        fieldName: '字段名',
        fieldType: '字段类型',
        fieldLength: '长度',
        fieldDecimalLength: '小数位',
        isRequired: '是否必填',
        isQueryItem: '列表查询项',
        queryType: '查询方式',
        isDisplayed: '管理端列表显示',
        displaySet: '显示设置',
        isConsoleDisplayed: '作业台列表显示',
        isPdaDisplayed: '手持机过滤项',
        isRelationPicture: '关联图片',
        moveUp: '上移',
        moveDown: '下移',
        customAttributes: '自定义属性',
        syncField: '同步字段',
        syncSuccess: '同步完成！',
        syncFieldTips: '确认同步基础信息表中的字段？',
        deleteTips: '确认删除该属性吗？',
        fieldNameValidateTips: '以英文字母开头，只能包含字母、数字、下划线！'
    },
    dataType: {
        string: '字符串',
        integer: '整数',
        double: '小数',
        datetime: '时间',
        date: '日期',
        text: '大文本'
    },
    queryType: {
        range: '范围',
        matching: '模糊',
        equalse: '精确'
    },
    customerCodec: {
        codecTitle: '系统编解码规则设置',
        codecType: '编码解码方式',
        codecProtocol: '远程编解码协议',
        codecDprotocol: '桌面端编解码协议',
        codecPprotocol: '手持端编解码协议',
        jarFile: 'jar文件',
        jsScript: 'javascript脚本',
        dynamicBinding: '动态绑定',
        encodeScript: '编码脚本',
        decodeScript: '解码脚本',
        validJarFileMsg: '请选择协议文件',
        validScriptMsg: '请输入脚本',
        setCodecSuccessTip: '设置标签编解码模式成功'
    },
    optProtocol: {
        addProtocol: '添加协议',
        updateProtocol: '修改协议',
        protocolType: '协议（驱动）分类',
        protocolName: '协议（驱动）名称',
        protocolDispfileName: '协议（驱动）文件',
        deskReader: '桌面读写器控制驱动',
        PDAReader: '手持机一体机控制驱动',
        autoEquipment: '自动化设备控制驱动',
        orderProcess: '接口对接适配协议',
        remoteCodec: '远程编解码协议',
        desktopCodec: '桌面端编解码协议',
        pdaCodec: '手持端编解码协议',
        protocolNameRequire: '请输入协议名称',
        deleteProtocolConfirmTip: '确认要删除本协议（驱动）吗？'
    },
    platformSysPara: {
        deploymentModeTabName: '系统部署方式设置',
        deploymentModeLabel: '部署方式',
        single: '单机部署',
        cluster: '集群部署',
        validDeploymentModeMsg: '请选择部署方式',
        setdeploymentModeSuccessTip: '设置部署方式成功',
        mqConfigTabName: '消息服务配置',
        mqUrlLabel: '队列地址',
        mqUrlTip: '请输入消息服务器IP地址或域名',
        mqPortLabel: '队列端口',
        mqPortTip: '请输入消息服务器端口号',
        mqAccountLabel: '连接账号',
        mqAccountTip: '请输入消息服务器连接账号',
        mqPasswordLabel: '连接密码',
        mqPasswordTip: '请输入消息服务器连接密码',
        setmqConfigSuccessTip: '设置消息服务配置信息成功',
        fileServerConfigTabName: '文件服务配置',
        fileServerUrlLabel: '文件服务地址',
        fileServerUrlTip: '请输入文件服务器IP地址或域名',
        fileServerPortLabel: '文件服务端口',
        fileServerPortTip: '请输入文件服务器端口号',
        fileServerAccountLabel: '连接账号',
        fileServerAccountTip: '请输入文件服务器连接账号',
        fileServerPasswordLabel: '连接密码',
        fileServerPasswordTip: '请输入文件服务器连接密码',
        setFileServerConfigSuccessTip: '设置文件服务配置信息成功'
    },
    cusOrgNode: {
        nodeName: '部门名称',
        nodeCode: '部门编号',
        nodeType: '部门类型',
        contactsName: '联系人',
        contactsNumber: '联系电话',
        channelCode: '渠道编号',
        pchannelCode: '上级渠道编号',
        addOrgNodeTitle: '添加部门',
        updateOrgNodeTitle: '修改部门',
        viewOrgNodeTitle: '查看部门信息',
        selectedSelfErrMsg: '不能选择自己作为上级部门',
        depSelectDlg: '部门选择',
        factory: '工厂',
        warehouse: '仓库',
        shop: '门店',
        reservoirArea: '内部库区',
        shopDisSite: '门店展区',
        shopFittingroom: '试衣间',
        customer: '代理商或客户',
        tagVendor: '标签供应商',
        headOffice: '总公司',
        parentNode: '上级部门',
        validNodeTypeMsg: '请选择部门类型',
        deleteCurOrgNodeConfirmTip: '确认要删除本部门吗？'
    },
    labelPurchase: {
        insertDialogTitle: '新增',
        viewDialogTitle: '查看明细',
        deleteTips: '确认删除该单据吗？',
        handleSuccessTips: '订单已确认并已生成生产数据',
        senderName: '生产方名称',
        receiverName: '采购方名称',
        tagItems: '标签列表',
        inputSkuTips: '请输入SKU',
        estAmount: '数量',
        selectSkuTips: '请选择SKU',
        enterAmountTips: '请输入数量',
        pleaseInput: '请输入',
        generateEPC: '生成EPC',
        dataFileColumn: '文件中的列名',
        deleteItemTips: '确认删除该条目吗？'
    },
    expressConfig: {
        expressTitle: '快递平台对接配置',
        envSwitch: '生产或测试切换',
        dockingParaTitle: '连接参数配置',
        receiveMapTitle: '收件方字段配置',
        senderMapTitle: '发件方字段配置',
        country: '国家字段',
        province: '省字段',
        city: '市字段',
        county: '县字段',
        address: '详址字段',
        contact: '联系人字段',
        phone: '联系电话字段',
        comanyName: '公司名称',
        productDesc: '快递商品名',
        expressRemark: '备注',
        curEnv: '当前环境',
        curEnvValProd: '生产环境',
        curEnvValDev: '测试环境',
        needInput: '需要填写',
        billTemplateTitle: '面单模板配置'
    },
    cusScene: {
        menuName: '场景名称',
        menuCode: '场景编号',
        menuIcon: '场景图标',
        menuIndex: '场景顺序号',
        addSceneTitle: '创建场景',
        updateSceneTitle: '修改场景',
        addSuccessTip: '创建场景成功',
        updateSuccessTip: '修改场景成功',
        deleteConfirmTip: '确定要删除本场景吗？'
    },
    customerRole: {
        roleName: '角色名称',
        roleType: '角色类型',
        menus: '菜单',
        roleType_10: '管理员角色',
        roleType_20: '普通角色',
        deleteTips: '确定要删除该角色吗？',
        trackRange: '追溯范围'
    },
    baseInfo: {
        deleteTips: '确定要删除该条数据吗？',
        importText: '将文件拖到此处，或点击上传',
        importLimitText: '只能上传xls/xlsx文件!',
        currentFile: '当前文件',
        notUpload: '未上传',
        firstColumnHeader: '第一列是否表头',
        skuRepetitiveCoverage: 'sku重复覆盖',
        startColumnIndex: '开始行',
        endColumnIndex: '结束行',
        attribute: '属性',
        columnName: '列名',
        columnNameIndex: '第{}列',
        pictureSettings: '图片设置',
        deletePictureTips: '确定要删除该图片吗？',
        productPicture: '商品图片文件',
        zipFilterMsg: '只能上传zip文件，且压缩包中不能包含目录',
        uploadTip: '请上传商品图片压缩文件'
    },
    terminalUnit: {
        unitType: '终端类型',
        unitCode: '设备编号',
        desktop: '桌面工作台',
        pda: '手持设备',
        autoChannel: '自动化通道',
        mirror: '魔镜',
        deleteUnitConfirmTip: '确定要删除该终端设备吗？'
    },
    cusLicense: {
        cardTitle: '设置注册码'
    },
    order: {
        deleteTips: '确认删除该订单吗？',
        orderStatePending: '待处理',
        orderStateExecuting: '处理中',
        orderStateCompleted: '已完成'
    },
    orderItems: {
        actNum: '实际数',
        difNum: '差异数',
        number: '数量',
        estAmount: '预计数'
    },
    backup: {
        backDirTitle: '备份文件路径设置',
        fileDir: '备份文件路径',
        fileDirTip: '请输入备份文件所在目录',
        backupDirDesc: '数据备份路径'
    },
    sysLog: {
        logType: '日志类型',
        post: '添加',
        delete: '删除',
        put: '修改',
        logContent: '日志内容',
        deleteUnitConfirmTip: '确定要删除该日志吗？',
        moduleName: '模块名称',
        operator: '操作人',
        logTime: '请求时间',
    },
    versionInfo: {
        softwareName: '软件名称',
        softwareVersion: '版本号',
        updateTime: '更新时间',
        softwareFileName: '升级文件名',
        addVersionInfo: '添加版本信息',
        updateVersionInfo: '修改版本信息',
        softwareSize: '文件大小',
        deleteVersionInfoConfirmTip: '确定要删除该软件版本信息吗？'
    },
    report: {
        datasetName: '数据集名称',
        analysis: '解析',
        run: '运行',
        dbType: '数据库类型',
        resultColumn: '结果列',
        parameters: '查询参数',
        parameterNotObtained: '未获取到参数',
        param: '参数',
        name: '名称',
        type: '类型',
        tempVal: '临时值',
        selectDateTime: '选择日期时间',
        resultsOfEnforcement: '执行结果',
        takeUpTime: '耗时',
        reportName: '报表名称',
        chartType: '图标类型',
        dataset: '数据集',
        reportJsDef: 'js片段',
        isMenu: '是否生成菜单',
        pieChart: '饼图',
        columnChart: '柱图',
        lineChart: '折线图',
        appScene: '隶属场景',
        deleteTips: '确认删除当前报表吗？',
        bordName: '看板名称',
    }
    
}
